/* MainSection.css */
.main-section {
  padding: 4em;
  height: 100vh;
  display: flex;
  justify-content: right;
}

.centered-content {
  text-align: right;
}

.title {
  font-size: 6em;
  margin: 0;
  font-weight: 200;
}

.subtitle {
  font-size: 18px;
}

.bottom-left {
  padding: 4em;
  position: absolute;
  bottom: 0px;
  left: 40px;
}

.bottom-right {
  padding: 4em;
  position: absolute;
  bottom: 20px;
  right: 0px;
  text-align: right;
}

/* Media Queries for responsiveness */
@media (max-width: 768px) {
  .title {
    font-size: 50px;
  }

  .subtitle {
    font-size: 14px;
  }

  .bottom-left, .bottom-right {
    font-size: 12px;
  }
}

@media (max-width: 480px) {
  .title {
    font-size: 30px;
  }

  .subtitle {
    font-size: 12px;
  }

  .bottom-left, .bottom-right {
    font-size: 10px;
  }
}