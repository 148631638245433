
/* Define the custom font faces */
@font-face {
  font-family: 'GlacialIndifference';
  src: url('/src/assets/fonts/GlacialIndifference-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GlacialIndifference';
  src: url('/src/assets/fonts/GlacialIndifference-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'GlacialIndifference';
  src: url('//src/assets/fonts/GlacialIndifference-Italic.otf') format('opentype');
  font-weight: normal;
  font-style: italic;
}

body {
  background-color: #ffffff;
  font-family: 'GlacialIndifference'
}

