.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4em;
  width: 100%; /* Ensure the header takes the full width of the page */
  box-sizing: border-box; /* Include padding in the element's total width and height */
}

.nav-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.nav-links {
  display: flex;
  gap: 4em;
  list-style: none;
}

.end-links {
  margin-left: auto;
  display: flex;
  gap: 4em; /* Ensure the links have some space between them */
}

.nav-links a, .contact-link {
  text-decoration: none;
  color: black;
  font-size: 20px;
}

.nav-links a:hover, .contact-link:hover {
  text-decoration: underline;
}

/* Media Queries for responsiveness */
@media (max-width: 768px) {
  .nav-links {
    flex-direction: column;
    gap: 10px;
  }

  .nav-links a, .contact-link {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .nav-links {
    flex-direction: column;
    gap: 5px;
  }

  .nav-links a, .contact-link {
    font-size: 12px;
  }
}