.contact-form-container{
    display: flex;
    flex-direction: column;
    border-top: solid;
    border-left: solid;
    padding: 2em;
}

.message-input{
    vertical-align: top;
}

.contact-form{
    display: flex;
    flex-direction: column;
}

.contact-me-header{
    font-size: 4em;
}

.form-group{
    margin: 0.5em;
    border-bottom: solid;
}

.contact-form input {
    width: 75%;
    margin: 0em 0em 1em 1em ;
    font-size: 1em;
    border: none;
    font-family: 'GlacialIndifference', sans-serif;
    background-color: transparent; /* Remove background */
    }

.contact-form input:focus,
.contact-form textarea:focus {
  outline: none; /* Remove outline on focus */
  background-color: transparent; /* Ensure background remains transparent on focus */
}

.contact-form button {
    margin: 1em 0em;
    padding: 0.75em 1.5em;
    font-size: 1em;
    color: #f4efed;
    background-color: black;
    border: none;
    border-radius: 3em;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 70%;
    align-self: center;
}
