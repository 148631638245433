.architecture-page {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 2em;
}

.image-column {
  display: flex;
  flex-direction: column;
  gap: 1em; /* Space between images */
}

.architecture-image {
  width: 100%;
  max-width: 950px; /* Adjust the max-width as needed */
  height: auto;
  border-radius: 8px; /* Optional: Add some border radius */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: Add some shadow */
}